<template>
    <BT-List
        :canDelete="false"
        dense
        :height="height"
        hideFooter
        isSimple
        navigation="stock-increments"
        :onPullSuccessAsync="pullIncrements"
        :params="cParams">
        <template slot-scope="{ item }">
            <!-- <v-list-item v-if="item != null && item.data != null" dense> -->
                <v-list-item-content>
                    <v-list-item-subtitle>
                        <BT-Entity
                            v-if="measurementID == null"
                            navigation="measurements"
                            :itemValue="item.data.measurementID"
                            itemText="measurementName" />
                        <BT-Entity
                            v-else-if="productID == null"
                            navigation="products"
                            :itemValue="item.data.productID"
                            itemText="productName" />
                    </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-content>
                    <v-list-item-subtitle>
                        <v-text-field
                            v-model.number="item.data.units"
                            :disabled="!isEditing"
                            :loading="item.isLoading"
                            label="Units"
                            hide-details
                            @change="updateTracking(item)"
                            type="number" />
                    </v-list-item-subtitle>
                </v-list-item-content>
        </template>
    </BT-List>
</template>

<script>
export default {
    name: 'BT-Increments',
    data: function() {
        return {
            itemList: null,
            increments: [],
            loadingMsg: null,
        }
    },
    props: {
        height: {
            type: String,
            default: null
        },
        isEditing: {
            type: Boolean,
            default: false
        },
        measurementID: {
            type: String,
            default: null
        },
        productID: {
            type: String,
            default: null
        }
    },
    computed: {
        cParams() {
            if (this.productID != null) {
                return { productID: this.productID };
            }
            else if (this.measurementID != null) {
                return { measurementID: this.measurementID };
            }
            return null;
        }
    },
    watch: {
        loadingMsg(val) {
            this.$emit('update:loadingMsg', val);
        }
    },
    methods: {
        async pullIncrements(increments) {
            this.loadingMsg = 'Loading';

            if (this.productID != null) {
                var measurements = await this.$BlitzIt.store.getAll('measurements');

                this.loadingMsg = null;
                return measurements.map(x => {
                    var eInc = increments.find(y => y.measurementID == x.id);

                    var d = {
                        id: eInc != null ? eInc.id : null,
                        rowVersion: eInc != null ? eInc.rowVersion : null,
                        productID: this.productID,
                        units: eInc != null ? eInc.units : 0,
                        measurementID: x.id
                    }

                    return d;
                })
                .map(x => { 
                    return { 
                        isChanged: false, 
                        isLoading: false, 
                        original: JSON.stringify(x), 
                        data: this.copyDeep(x) }
                });
            }
            else if (this.measurementID != null) {
                var products = await this.$BlitzIt.store.getAll('products');

                this.loadingMsg = null;
                return products.map(x => {
                    var eInc = increments.find(y => y.productID == x.id);

                    var d = {
                        id: eInc != null ? eInc.id : null,
                        rowVersion: eInc != null ? eInc.rowVersion : null,
                        productID: x.id,
                        units: eInc != null ? eInc.units : 0,
                        measurementID: this.measurementID
                    }

                    return d;
                })
                .map(x => { 
                    return { 
                        isChanged: false, 
                        isLoading: false, 
                        original: JSON.stringify(x), 
                        data: this.copyDeep(x) }
                });
            }
            else {
                this.loadingMsg = null;
                return [];
            }
        },
        updateTracking(item) {
            //item.isChanged = item.original != JSON.stringify(item.data);
            if (item.original != JSON.stringify(item.data)) {
                //save
                this.saveIncrement(item);
            }
        },
        async saveIncrement(increment) {
            increment.isLoading = true;
            
            try {
                if (increment.data.units == null || increment.data.units == 0) {
                    console.log('d');
                    if (increment.data.id != null) {
                        console.log('deleting');
                        await this.$BlitzIt.store.delete('stock-increments', increment.data.id);
                        increment.data.rowVersion = null;
                        increment.data.id = null;
                        increment.original = JSON.stringify(increment.data);
                        increment.isChanged = false;
                    }
                }
                else if (increment.data.id == null) {
                    var postRes = await this.$BlitzIt.store.post('stock-increments', increment.data);
                    increment.original = JSON.stringify(postRes);
                    increment.data = postRes;
                    increment.isChanged = false;
                }
                else {
                    var patchRes = await this.$BlitzIt.store.patch('stock-increments', increment.data);
                    increment.original = JSON.stringify(patchRes);
                    increment.data = patchRes;
                    increment.isChanged = false;
                }
            }
            finally {
                increment.isLoading = false;
            }
        }
    }
}
</script>